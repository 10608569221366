import {AuthActionTypesEnum} from './types';
import MainAction from '../model/main.action';

export const LOG_IN_ACTION: MainAction = {
    type: AuthActionTypesEnum.LOG_IN
};

export const LOG_OUT_ACTION: MainAction = {
    type: AuthActionTypesEnum.LOG_OUT
};

export const NOT_ALLOWED_ACTION: MainAction = {
    type: AuthActionTypesEnum.NOT_ALLOWED
};

export const RESET_ALLOWED_ACTION: MainAction = {
    type: AuthActionTypesEnum.RESET_ALLOWED
};
