import React, {FunctionComponent, ReactElement, useEffect, useState} from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import Job from 'model/job';
import './jobs.component.scss';
import {connect} from 'react-redux';
import {mapLanguageToProps} from 'services/language.service';
import {FormattedMessage} from 'react-intl';
import PropsLanguage from 'model/props-language';

interface JobsComponentProps {
    jobs: Job[];
}

const JobsComponent: FunctionComponent<JobsComponentProps & PropsLanguage> = ({ jobs, language }): ReactElement => {
    const [ first, setFirst ] = useState();
    const [ fromToRow, setFromToRow ] = useState('');
    const rows = 5;

    useEffect(() => {
        setFirst(0);
        setFromToRow(`${jobs.length ? '1' : '0'} - ${rows > jobs.length ? jobs.length : rows}`);
    }, [jobs]);

    const runAtBody = (rowData: Job): ReactElement => {
        return <span>{rowData.runAt.locale(language.value).format('MMM DD, YYYY, HH:mm')}</span>;
    };

    const paginate = (e: { first: number }): void => {
        const limit = e.first + rows;
        setFirst(e.first);
        setFromToRow(`${e.first + 1} - ${limit > jobs.length ? jobs.length : limit}`);
    };

    return <div className="jobs-component">
        <DataTable value={jobs} paginator={true} rows={rows} first={first} onPage={paginate}>
            <Column field="jid" header="ID"/>
            <Column field="job" header={<FormattedMessage id="job"/>}/>
            <Column field="queue" header={<FormattedMessage id="queue"/>}/>
            <Column field="runAt" header={<FormattedMessage id="date"/>} sortable={true} body={runAtBody}/>
        </DataTable>
        <span className="paginator-info">{`${fromToRow} / ${jobs.length}`}</span>
    </div>;
};

export default connect(mapLanguageToProps)(JobsComponent);
