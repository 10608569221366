/* istanbul ignore file */
import Queue from './queue';

export default class QueueImp implements Queue {

    name: string;

    backlog: number;

    latency: number;

    constructor(name: string = '', backlog: number = 0, latency: number = 0) {
        this.name = name;
        this.backlog = backlog;
        this.latency = latency;
    }
}
