import React, {FunctionComponent, ReactElement} from 'react';
import './processes.component.scss';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import Process from 'model/process';
import {FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import {mapLanguageToProps} from 'services/language.service';
import PropsLanguage from 'model/props-language';

interface ProcessesComponentProps {
    processes: Process[];
}

const ProcessesComponent: FunctionComponent<ProcessesComponentProps & PropsLanguage> = ({processes, language}): ReactElement => {
    const queuesBody = (rowData: Process): ReactElement => {
        return <span>{JSON.stringify(rowData.queues)}</span>;
    };

    const startedAtBody = (rowData: Process): ReactElement => {
        return <span>{rowData.startedAt.locale(language.value).format('MMM DD, YYYY, HH:mm')}</span>;
    };

    return <div className="processes-component">
        <h3><FormattedMessage id="processes"/></h3>
        <DataTable value={processes}>
            <Column field="queues" header={<FormattedMessage id="queues"/>} body={queuesBody}/>
            <Column field="startedAt" header={<FormattedMessage id="started_at"/>} sortable={true} body={startedAtBody}/>
            <Column field="concurrency" header={<FormattedMessage id="concurrency"/>}/>
            <Column field="busy" header={<FormattedMessage id="busy"/>}/>
        </DataTable>
    </div>;
};

export default connect(mapLanguageToProps)(ProcessesComponent);
