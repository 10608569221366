import React, {FunctionComponent, ReactElement} from 'react';
import './header.component.scss';
import app from 'firebase/app';
import 'firebase/auth';
import LanguageSelectorComponent from './components/language-selector/language-selector.component';
import {FormattedMessage} from 'react-intl';

const HeaderComponent: FunctionComponent = (): ReactElement => {
    return <div className="header-component row">
        <div className="col-md-4 col-xs-6">
            <img alt="Selectra" src={process.env.PUBLIC_URL + '/selectra-blue.png'} />
        </div>
        <div className="col-md-4 hidden-mobile">
            <h2>Sidekiq</h2>
        </div>
        <div className="col-md-4 col-xs-6 button-container">
            <LanguageSelectorComponent/>
            <button className="btn" onClick={() => app.auth().signOut()}><FormattedMessage id="sign_out"/></button>
        </div>
    </div>;
};

export default HeaderComponent;
