/* istanbul ignore file */
import History from './history';
import {JsonObject, JsonProperty} from 'json2typescript';
import HistoryRecord from './history-record';
import {HistoryRecordDeserializer} from './deserializers/history-record.deserializer';

@JsonObject('HistoryImp')
export default class HistoryImp implements History {

    @JsonProperty('processed', HistoryRecordDeserializer)
    processed: HistoryRecord[];

    @JsonProperty('failed', HistoryRecordDeserializer)
    failed: HistoryRecord[];

    constructor(failed: HistoryRecord[] = [], processed: HistoryRecord[] = []) {
        this.failed = failed;
        this.processed = processed;
    }
}
