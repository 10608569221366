import React, {Fragment, FunctionComponent, ReactElement, useEffect, useState} from 'react';
import {Chart} from 'primereact/chart';
import History from 'model/history';
import HistoryRecord from 'model/history-record';
import {FormattedMessage, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {mapLanguageToProps} from 'services/language.service';
import PropsLanguage from 'model/props-language';

export interface ChartComponentProps {
    history: History;
    intl: any;
}

const ChartComponent: FunctionComponent<ChartComponentProps & PropsLanguage> = ({history, language, intl}): ReactElement => {
    const [data, setData] = useState({});
    const chartOptions = {
        animation: false
    };

    useEffect(() => {
        setData({
            labels: history.failed.map((record: HistoryRecord) => record.date.locale(language.value).format('MMM DD')).reverse(),
            datasets: [
                {
                    label: intl.formatMessage({ id: 'processed' }),
                    data: history.processed.map((record: HistoryRecord) => record.amount).reverse(),
                    fill: false,
                    backgroundColor: '#1460AA',
                    borderColor: '#1460AA'
                },
                {
                    label: intl.formatMessage({ id: 'failed' }),
                    data: history.failed.map((record: HistoryRecord) => record.amount).reverse(),
                    fill: false,
                    backgroundColor: '#ED0706',
                    borderColor: '#ED0706'
                }
            ]
        });
    }, [history, language, intl]);

    return <Fragment>
        <h3><FormattedMessage id="jobs"/></h3>
        <Chart type="line" data={data} options={chartOptions} />
    </Fragment>;
};

export default connect(mapLanguageToProps)(injectIntl(ChartComponent));
