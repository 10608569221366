import axios from 'axios';
import {JsonConvert} from 'json2typescript';
import ProjectInformationImp from 'model/project-information.imp';
import {APP_CONSTANTS} from 'config/app.config';
import {HIDE_LOADING_ACTION, SHOW_LOADING_ACTION} from 'store/loading/actions';

const jsonConvert: JsonConvert = new JsonConvert();

export const getSidekiq = (dispatch: Function, setProjectInfo: Function, endpoint: string): void => {
    dispatch(SHOW_LOADING_ACTION);

    axios.get(APP_CONSTANTS.ENDPOINTS.PROJECT_INFO + endpoint)
        .then(res => setProjectInfo(jsonConvert.deserializeObject(res.data, ProjectInformationImp)))
        .catch(err => setProjectInfo(new ProjectInformationImp()))
        .finally(() => dispatch(HIDE_LOADING_ACTION));
};
