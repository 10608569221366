import React, {FunctionComponent, ReactElement, useState} from 'react';
import './login.component.scss';
import app from 'firebase/app';
import 'firebase/auth';
import {connect} from 'react-redux';
import PropsDispatch from 'model/props-dispatch';
import {HIDE_LOADING_ACTION, SHOW_LOADING_ACTION} from 'store/loading/actions';
import {RESET_ALLOWED_ACTION} from 'store/auth/actions';
import MainState from 'store/model/main.state';
import {FormattedMessage} from 'react-intl';

export interface LoginComponentProps {
    isAllowed: boolean;
}

const LoginComponent: FunctionComponent<LoginComponentProps & PropsDispatch> = ({dispatch, isAllowed}): ReactElement => {
    const [ error, setError ] = useState('');
    const googleAuthProvider = new app.auth.GoogleAuthProvider();

    const login = (): void => {
        setError('');
        dispatch(SHOW_LOADING_ACTION);
        dispatch(RESET_ALLOWED_ACTION);

        app.auth().signInWithPopup(googleAuthProvider)
            .catch((err) => {
                setError(err.code);
                dispatch(HIDE_LOADING_ACTION);
            });
    };

    return <div className="login-component">
        <img className="selectra-logo" alt="logo" src={process.env.PUBLIC_URL + '/selectra.svg'}/>
        <div className="google-login">
            <h2><FormattedMessage id="sign_in"/></h2>
            <button className="btn btn--md btn--block" onClick={login}>
                <img src={process.env.PUBLIC_URL + '/google.png'} alt="Google"/> <FormattedMessage id="sign_in_with_google"/>
            </button>
            { error && <small className="error"><FormattedMessage id={error}/></small> }
            { !isAllowed && <small className="error"><FormattedMessage id="auth/not-allowed"/></small> }
        </div>
    </div>;
};

/* istanbul ignore next */
const mapStateToProps = (state: MainState): LoginComponentProps => ({
    isAllowed: state.auth.isAllowed
});

export default connect(mapStateToProps)(LoginComponent);

export { LoginComponent as LoginComponentDisconnected };
