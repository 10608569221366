import {JsonConverter, JsonCustomConvert} from 'json2typescript';
import Queue from '../queue';
import QueueImp from '../queue.imp';

@JsonConverter
export class QueuesDeserializer implements JsonCustomConvert<Queue[]> {
    deserialize = (queues?: any): Queue[] => {
        return queues ? Object.keys(queues).map((name: string) => new QueueImp(name, queues[name].backlog, queues[name].latency)) : [];
    };

    serialize = (queues: Queue[]): string => {
        return JSON.stringify(queues);
    };
}
