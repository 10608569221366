interface AppConfig {
    ROUTES: {
        DASHBOARD: string;
        LOGIN: string;
    };
    ENDPOINTS: {
        PROJECT_INFO: string;
    };
    DATASTORE: {
        USERS_COLLECTION: string;
        WHITELIST_DOC: string;
    };
}

export const APP_CONSTANTS: AppConfig = {
    ROUTES: {
        DASHBOARD: '/dashboard',
        LOGIN: '/login'
    },
    ENDPOINTS: {
        PROJECT_INFO: 'https://europe-west2-sidekiq-selectra-io-265314.cloudfunctions.net/api/project?endpoint='
    },
    DATASTORE: {
        USERS_COLLECTION: 'USERS',
        WHITELIST_DOC: 'WHITELIST'
    }
};
