/* istanbul ignore file */
import Stats from './stats';
import {JsonObject, JsonProperty} from 'json2typescript';

@JsonObject('StatsImp')
export default class StatsImp implements Stats {

    @JsonProperty('processed')
    processed: number;

    @JsonProperty('failed')
    failed: number;

    @JsonProperty('scheduled_size')
    scheduled: number;

    @JsonProperty('retry_size')
    retry: number;

    @JsonProperty('dead_size')
    dead: number;

    @JsonProperty('processes_size')
    processes: number;

    @JsonProperty('workers_size')
    workers: number;

    @JsonProperty('enqueued')
    enqueued: number;

    constructor(processed: number = 0, failed: number = 0, scheduled: number = 0, retry: number = 0, dead: number = 0, processes: number = 0, workers: number = 0, enqueued: number = 0) {
        this.processed = processed;
        this.failed = failed;
        this.scheduled = scheduled;
        this.retry = retry;
        this.dead = dead;
        this.processes = processes;
        this.workers = workers;
        this.enqueued = enqueued;
    }
}
