import MainAction from '../model/main.action';
import {AuthActionTypesEnum} from './types';
import AuthState from './model/auth.state';
import AuthStateImp from './model/auth.state.imp';
import {ReduxTypesEnum} from '../model/types';

const initialState: AuthState = new AuthStateImp();

const authReducer = (state = initialState, action: MainAction) => {
    switch (action.type) {
        case ReduxTypesEnum.RESET: {
            return new AuthStateImp();
        }
        case AuthActionTypesEnum.LOG_IN: {
            return Object.assign({}, state, {
                isLoggedIn: true
            });
        }
        case AuthActionTypesEnum.LOG_OUT: {
            return Object.assign({}, state, {
                isLoggedIn: false
            });
        }
        case AuthActionTypesEnum.NOT_ALLOWED: {
            return Object.assign({}, state, {
                isAllowed: false
            });
        }
        case AuthActionTypesEnum.RESET_ALLOWED: {
            return Object.assign({}, state, {
                isAllowed: true
            });
        }
        default:
            return state;
    }
};

export default authReducer;
