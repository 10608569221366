const FIREBASE_CONFIG: Object = {
    apiKey: 'AIzaSyCaq7-Ig_qfsdgshgUA_7g4PYKi-4ZiKJ0',
    authDomain: 'sidekiq-selectra-io-265314.firebaseapp.com',
    databaseURL: 'https://sidekiq-selectra-io-265314.firebaseio.com',
    projectId: 'sidekiq-selectra-io-265314',
    storageBucket: 'sidekiq-selectra-io-265314.appspot.com',
    messagingSenderId: '133461542714',
    appId: '1:133461542714:web:6476e52a13f21af73f7203'
};

export default FIREBASE_CONFIG;
