/* istanbul ignore file */
import Process from './process';
import Stats from './stats';
import History from './history';
import ProjectInformation from './project-information';
import StatsImp from './stats.imp';
import ProcessImp from './process.imp';
import HistoryImp from './history.imp';
import {JsonObject, JsonProperty} from 'json2typescript';
import Job from './job';
import JobImp from './job.imp';
import Queue from './queue';
import {QueuesDeserializer} from './deserializers/queues.deserializer';

@JsonObject('ProjectInformationImp')
export default class ProjectInformationImp implements ProjectInformation {

    @JsonProperty('queues', QueuesDeserializer)
    queues: Queue[];

    @JsonProperty('processes', [ProcessImp])
    processes: Process[];

    @JsonProperty('stats', StatsImp)
    stats: Stats;

    @JsonProperty('stats_history', HistoryImp)
    history: History;

    @JsonProperty('jobs', [JobImp])
    jobs: Job[];

    constructor(queues: Queue[] = [], processes: Process[] = [], stats: Stats = new StatsImp(), history: History = new HistoryImp(), jobs: Job[] = []) {
        this.queues = queues;
        this.processes = processes;
        this.stats = stats;
        this.history = history;
        this.jobs = jobs;
    }
}
