/* istanbul ignore file */
import moment, {Moment} from 'moment';
import Job from './job';
import {JsonObject, JsonProperty} from 'json2typescript';
import {MomentDeserializer} from './deserializers/moment.deserializer';

@JsonObject('JobImp')
export default class JobImp implements Job {

    @JsonProperty('jid')
    jid: string;

    @JsonProperty('queue')
    queue: string;

    @JsonProperty('job')
    job: string;

    @JsonProperty('run_at', MomentDeserializer)
    runAt: Moment;

    constructor(jid: string = '', queue: string = '', job: string = '', runAt: Moment = moment()) {
        this.jid = jid;
        this.queue = queue;
        this.job = job;
        this.runAt = runAt;
    }
}
