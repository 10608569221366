export interface Project {
    name: string;
    endpoint: string;
}

interface DashboardConfig {
    PROJECTS: Project[];
}

export const DASHBOARD_CONSTANTS: DashboardConfig = {
    PROJECTS: [
        { name: 'Zoho', endpoint: 'https://zoho.selectra.io/sidekiq' },
        { name: 'Mew', endpoint: 'https://mew.selectra.io/sidekiq' },
        { name: 'Energia', endpoint: 'https://cloud.energia.selectra.io/sidekiq' },
        { name: 'Beatrice', endpoint: 'https://v2.beatrice.selectra.io/api/v1/sidekiq' },
        { name: 'Tictac', endpoint: 'https://tictac.selectra.io/api/v1/sidekiq' },
        { name: 'Mudsdale', endpoint: 'https://mudsdale.selectra.io/sidekiq' },
        { name: 'Ditto', endpoint: 'https://ditto.selectra.io/api/v1/sidekiq' },
        { name: 'Landorus', endpoint: 'https://landorus.selectra.io/api/v1/sidekiq' },
        { name: 'Borgan', endpoint: 'https://staging-dot-borgan-selectra-io-244407.ew.r.appspot.com/v1/sidekiq' },
        { name: 'Arenine', endpoint: 'https://staging-dot-arenine-selectra-io.appspot.com/v1/sidekiq' }
    ]
};
