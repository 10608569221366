import React, {FunctionComponent, ReactElement, useEffect, useState} from 'react';
import './dashboard.component.scss';
import {DASHBOARD_CONSTANTS, Project} from './config/dashboard.config';
import HeaderComponent from './components/header/header.component';
import TitleComponent from './components/title/title.component';
import GeneralStatsComponent from './components/general-stats/general-stats.component';
import ProcessesComponent from './components/processes/processes.component';
import QueuesComponent from './components/queues/queues.component';
import ChartComponent from './components/chart/chart.component';
import JobsComponent from './components/jobs/jobs.component';
import ProjectInformationImp from 'model/project-information.imp';
import {getSidekiq} from './services/dashboard.service';
import PropsDispatch from 'model/props-dispatch';
import {connect} from 'react-redux';

const DashboardComponent: FunctionComponent<PropsDispatch> = ({dispatch}): ReactElement => {
    const [ currentProject, setCurrentProject ] = useState(DASHBOARD_CONSTANTS.PROJECTS[0]);
    const [ projectInfo, setProjectInfo ] = useState(new ProjectInformationImp());

    useEffect(() => {
        getSidekiq(dispatch, setProjectInfo, currentProject.endpoint);
    }, [currentProject.endpoint, dispatch]);

    return <div className="dashboard-component">
        <HeaderComponent/>
        <div className="container">
            <div className="row">
                <div className="col-xs-12 tabs">
                    { DASHBOARD_CONSTANTS.PROJECTS.map((project: Project, index: number) => {
                        return <button key={index} onClick={() => setCurrentProject(project)}
                                       className={`btn btn--md ${project === currentProject ? 'selected' : 'not-selected'}`}>
                            { project.name }
                        </button>
                    }) }
                </div>
                <div className="col-xs-12">
                    <TitleComponent project={currentProject.name} onRefresh={() => getSidekiq(dispatch, setProjectInfo, currentProject.endpoint)}/>
                </div>
                <div className="col-xs-12">
                    <GeneralStatsComponent stats={projectInfo.stats}/>
                </div>
                <div className="col-xs-12 col-md-6">
                    <ProcessesComponent processes={projectInfo.processes}/>
                </div>
                <div className="col-xs-12 col-md-6">
                    <QueuesComponent queues={projectInfo.queues}/>
                </div>
                <div className="col-xs-12">
                    <ChartComponent history={projectInfo.history}/>
                </div>
                <div className="col-xs-12">
                    <JobsComponent jobs={projectInfo.jobs}/>
                </div>
            </div>
        </div>
    </div>;
};

export default connect()(DashboardComponent);

export { DashboardComponent as DashboardComponentDisconnected };
