/* istanbul ignore file */
import AuthState from './auth.state';

export default class AuthStateImp implements AuthState {
    isLoggedIn: boolean;
    isAllowed: boolean;

    constructor(isLoggedIn: boolean = false, isAllowed: boolean = true) {
        this.isLoggedIn = isLoggedIn;
        this.isAllowed = isAllowed;
    }
}
