import moment from 'moment';
import HistoryRecord from '../history-record';
import {JsonConverter, JsonCustomConvert} from 'json2typescript';
import HistoryRecordImp from '../history-record.imp';

@JsonConverter
export class HistoryRecordDeserializer implements JsonCustomConvert<HistoryRecord[]> {
    deserialize = (historyRecords?: any): HistoryRecord[] => {
        return historyRecords ? Object.keys(historyRecords).map((date: string) => new HistoryRecordImp(moment(date), historyRecords[date])) : [];
    };

    serialize = (historyRecords: HistoryRecord[]): string => {
        return JSON.stringify(historyRecords);
    };
}
