import {combineReducers} from 'redux';
import authReducer from './auth/reducer';
import languageReducer from './language/reducer';
import loadingReducer from './loading/reducer';

export const mainReducer = combineReducers({
    auth: authReducer,
    language: languageReducer,
    loading: loadingReducer
});
