/* istanbul ignore file */
import moment, {Moment} from 'moment';
import Process from './process';
import {JsonObject, JsonProperty} from 'json2typescript';
import {MomentDeserializer} from './deserializers/moment.deserializer';

@JsonObject('ProcessImp')
export default class ProcessImp implements Process {

    @JsonProperty('started_at', MomentDeserializer)
    startedAt: Moment;

    @JsonProperty('queues', [String])
    queues: string[];

    @JsonProperty('concurrency')
    concurrency: number;

    @JsonProperty('busy')
    busy: number;

    constructor(startedAt: Moment = moment(), queues: string[] = [], concurrency: number = 0, busy: number = 0) {
        this.startedAt = startedAt;
        this.queues = queues;
        this.concurrency = concurrency;
        this.busy = busy;
    }
}
