import React, {FunctionComponent, ReactElement} from 'react';
import './title.component.scss';

interface TitleComponentProps {
    project: string;
    onRefresh: Function;
}

const TitleComponent: FunctionComponent<TitleComponentProps> = ({project, onRefresh}): ReactElement => {
    return <div className="title-component row">
        <h2 className="col-offset-md-3 col-offset-xs-2 col-md-6 col-xs-8">{ project }</h2>
        <div className="col-md-3 col-xs-2 button-container">
            <button className="btn" onClick={() => onRefresh()}>
                <i className="pi pi-refresh"/>
            </button>
        </div>
    </div>;
};

export default TitleComponent;
