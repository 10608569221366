import React, {FunctionComponent, ReactElement} from 'react';
import './spinner.component.scss'
import MainState from 'store/model/main.state';
import {connect} from 'react-redux';

export interface SpinnerComponentProps {
    loading: boolean;
}

const SpinnerComponent: FunctionComponent<SpinnerComponentProps> = ({loading}): ReactElement => {
    return <div className={`${loading ? 'spinner-container centered' : 'hidden'}`}>
        <img src={process.env.PUBLIC_URL + '/loading.gif'} alt='Loading' />
    </div>
};

/* istanbul ignore next */
const mapStateToProps = (state: MainState): SpinnerComponentProps => ({
    loading: state.loading.loading
});

export default connect(mapStateToProps)(SpinnerComponent);

export { SpinnerComponent as SpinnerComponentDisconnected };
