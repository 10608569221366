import React, {FunctionComponent, ReactElement} from 'react';
import './App.scss';
import {Provider} from 'react-redux';
import {createStore} from 'redux';
import {mainReducer} from 'store/main.reducer';
import {composeWithDevTools} from 'redux-devtools-extension';
import MainComponent from 'components/main/main.component';
import app from 'firebase/app';
import FIREBASE_CONFIG from 'config/firebase.config';

import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

const App: FunctionComponent = (): ReactElement => {
  app.initializeApp(FIREBASE_CONFIG);

  return <Provider store={createStore(mainReducer, process.env.NODE_ENV === 'development' ? composeWithDevTools() : undefined)}>
    <MainComponent />
  </Provider>;
};

export default App;
