import React, {FunctionComponent, ReactElement} from 'react';
import './general-stats.component.scss';
import Stats from 'model/stats';
import {FormattedMessage} from 'react-intl';

interface GeneralStatsComponentProps {
    stats: Stats;
}

const GeneralStatsComponent: FunctionComponent<GeneralStatsComponentProps> = ({stats}): ReactElement => {
    return <div className="general-stats-component">
        <div className="processes-workers">
            <div>
                <span><FormattedMessage id="processes"/></span>
                <h3>{ stats.processes }</h3>
            </div>
            <div>
                <span><FormattedMessage id="workers"/></span>
                <h3>{ stats.workers }</h3>
            </div>
        </div>
        <div className="stats">
            <div className="processed">
                <span><FormattedMessage id="processed"/></span>
                <h3>{ stats.processed }</h3>
            </div>
            <div className="failed">
                <span><FormattedMessage id="failed"/></span>
                <h3>{ stats.failed }</h3>
            </div>
            <div className="scheduled">
                <span><FormattedMessage id="scheduled"/></span>
                <h3>{ stats.scheduled }</h3>
            </div>
            <div>
                <span><FormattedMessage id="pending"/></span>
                <h3>{ stats.retry }</h3>
            </div>
            <div>
                <span><FormattedMessage id="dead"/></span>
                <h3>{ stats.dead }</h3>
            </div>
        </div>
    </div>;
};

export default GeneralStatsComponent;
