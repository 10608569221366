/* istanbul ignore file */
import moment, {Moment} from 'moment';
import HistoryRecord from './history-record';

export default class HistoryRecordImp implements HistoryRecord {
    date: Moment;
    amount: number;

    constructor(date: Moment = moment(), amount: number = 0) {
        this.date = date;
        this.amount = amount;
    }
}
