import React, {FunctionComponent, ReactElement, useEffect, useState} from 'react';
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom';
import {APP_CONSTANTS} from 'config/app.config';
import MainState from 'store/model/main.state';
import {connect} from 'react-redux';
import LoginComponent from 'components/login/login.component';
import DashboardComponent from 'components/dashboard/dashboard.component';
import SpinnerComponent from '../spinner/spinner.component';
import app, {User} from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import PropsDispatch from 'model/props-dispatch';
import {LOG_IN_ACTION, LOG_OUT_ACTION, NOT_ALLOWED_ACTION} from 'store/auth/actions';
import {HIDE_LOADING_ACTION, SHOW_LOADING_ACTION} from 'store/loading/actions';
import {IntlProvider} from 'react-intl';
import {i18n} from 'i18n/i18n';

export interface MainComponentProps {
    isLoggedIn: boolean;
    languageCode: string;
}

const MainComponent: FunctionComponent<MainComponentProps & PropsDispatch> = ({isLoggedIn, languageCode, dispatch}): ReactElement => {
    const [ dataLoaded, setDataLoaded ] = useState(false);

    useEffect(() => {
        dispatch(SHOW_LOADING_ACTION);

        app.auth().onAuthStateChanged((user: User | null) => {
            if (user) {
                app.firestore().collection(APP_CONSTANTS.DATASTORE.USERS_COLLECTION).doc(APP_CONSTANTS.DATASTORE.WHITELIST_DOC).get()
                    .then((whitelist: app.firestore.DocumentData) => {
                        const data = whitelist.data() || {};
                        const email = user.email || '';
                        const emailWhitelist = Object.keys(data);

                        if (emailWhitelist.includes(email)) {
                            dispatch(LOG_IN_ACTION);
                        } else {
                            app.auth().signOut();
                            dispatch(NOT_ALLOWED_ACTION);
                        }
                    }).finally(() => {
                        setDataLoaded(true);
                        dispatch(HIDE_LOADING_ACTION);
                    });
            } else {
                dispatch(LOG_OUT_ACTION);
                setDataLoaded(true);
                dispatch(HIDE_LOADING_ACTION);
            }
        });
    }, [dispatch]);

    return dataLoaded ? <IntlProvider locale={languageCode} messages={Object.assign(i18n)[languageCode]}>
            <BrowserRouter>
            <SpinnerComponent/>
            <Switch>
                <Route path={APP_CONSTANTS.ROUTES.LOGIN} render={() => (!isLoggedIn ? <LoginComponent/> : <Redirect to={APP_CONSTANTS.ROUTES.DASHBOARD}/>)}/>
                <Route path={APP_CONSTANTS.ROUTES.DASHBOARD} render={() => (isLoggedIn ? <DashboardComponent/> : <Redirect to={APP_CONSTANTS.ROUTES.LOGIN}/>)}/>
                <Redirect to={APP_CONSTANTS.ROUTES.DASHBOARD}/>
            </Switch>
        </BrowserRouter>
    </IntlProvider> : <SpinnerComponent/>;
};

/* istanbul ignore next */
const mapStateToProps = (state: MainState): MainComponentProps => ({
    isLoggedIn: state.auth.isLoggedIn,
    languageCode: state.language.language.value
});

export default connect(mapStateToProps)(MainComponent);

export { MainComponent as MainComponentDisconnected };
