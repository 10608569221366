import React, {FunctionComponent, ReactElement} from 'react';
import './queues.component.scss';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import Queue from 'model/queue';
import {FormattedMessage} from 'react-intl';

interface QueuesComponent {
    queues: Queue[];
}

const QueuesComponent: FunctionComponent<QueuesComponent> = ({queues}): ReactElement => {
    return <div className="queues-component">
        <h3><FormattedMessage id="queues"/></h3>
        <DataTable value={queues}>
            <Column field="name" header={<FormattedMessage id="name"/>} sortable={true}/>
            <Column field="backlog" header={<FormattedMessage id="backlog"/>}/>
            <Column field="latency" header={<FormattedMessage id="latency"/>}/>
        </DataTable>
    </div>;
};

export default QueuesComponent;
